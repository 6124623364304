import React from "react"
import Layout from "../components/common/layout"
import cartStyles from '../styles/pages/carrello.module.scss'



const PageNotFound = ({ location }) => {
  return (
    <Layout
      pageMeta={{
        title: "404 pagina non trovata",
        keywords: [""],
        description: "404 pagina non trovata",
        link: "/404",
      }}
      location={location}
    >
      <p className={cartStyles.notFound}>
        Errore 404 - pagina non trovata.
      </p>
      <p className={cartStyles.text}>La pagina che hai cercato e' stata rimossa o
        spostata.</p>
    </Layout>
  )
}

export default PageNotFound